import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { useSessionStorage } from "react-use";

// Components
import { SingleArtist } from "../components/homepage/single-artist";

const Page = styled.div`
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Grid = styled.ol`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  grid-auto-rows: 54px;

  padding: 0;
  margin: 0;

  & li {
    position: relative;
    font-family: "Recursive", monospace, "Helvetica Neue", "Lucida Grande",
      sans-serif;
    font-variation-settings: "MONO" 1, "CASL" 0, "wght" 400, "slnt" 0, "CRSV" 0;

    &.contact-link {
      & button {
        color: ${props => props.color};
        transition: 250ms color ease;
      }
    }
  }

  & li:not(.item) {
    opacity: ${props => props.opacity};
    transition: 500ms opacity ease;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    grid-auto-rows: 30px;
  }
`;

const Index = ({ data }) => {
  const [activeArtist, setActiveArtist] = useState(null);
  const [isGridVisible, setIsGridVisible] = useSessionStorage(
    "isGridVisible",
    true
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsGridVisible(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const artists = data.allPrismicArtist.edges.map((artist, index) => (
    <SingleArtist
      artist={artist}
      index={index}
      activeArtist={activeArtist}
      setActiveArtist={setActiveArtist}
      isGridVisible={isGridVisible}
    />
  ));

  return (
    <Page>
      <Grid
        opacity={isGridVisible ? 1 : 0}
        color={activeArtist === null ? `#000` : `#CCCCCC`}
      >
        <li className="item">
          <Link to={`/info/`}>{isGridVisible ? `A` : `Arc Editions`}</Link>
        </li>
        <li>B</li>
        <li className="item contact-link">
          <Link to={`/contact/`}>{isGridVisible ? `C` : `Contact`}</Link>
        </li>
        <li>D</li>
        <li>E</li>
        <li>F</li>
        <li>G</li>
        <li className="item">{artists[0]}</li>
        <li>I</li>
        <li>J</li>
        <li className="item">{artists[1]}</li>
        <li>L</li>
        <li>M</li>
        <li>N</li>
        <li>O</li>
        <li>P</li>
        <li>Q</li>
        <li className="item">{artists[2]}</li>
        <li className="item">{artists[3]}</li>
        <li>T</li>
        <li>U</li>
        <li className="item">{artists[4]}</li>
        <li>W</li>
        <li>X</li>
        <li>Y</li>
        <li>Z</li>
        <li>0</li>
        <li>1</li>
        <li>2</li>
        <li>3</li>
        <li>4</li>
        <li>5</li>
        <li>6</li>
        <li>7</li>
        <li>8</li>
        <li>9</li>
      </Grid>
    </Page>
  );
};

export default withPreview(Index);

export const query = graphql`
  {
    allPrismicArtist(sort: { fields: data___name___text, order: ASC }) {
      edges {
        node {
          url
          id
          data {
            name {
              text
            }
            thumbnail {
              alt
              fluid {
                srcWebp
                srcSetWebp
              }
              dimensions {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;
