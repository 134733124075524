import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

const Artist = styled.div`
  width: fit-content;

  color: ${props => props.color};
  transition: 250ms color ease;

  & .rest-of-name {
    opacity: ${props => props.opacity};
    transition: 500ms opacity ease;
  }
`;

const ActiveArtistImageContainer = styled.div`
  position: absolute;

  z-index: 10;

  opacity: ${props => props.opacity};
  transition: 250ms opacity ease;

  visibility: ${props => props.visibility};

  &.image-layout-0,
  &.image-layout-1 {
    top: 30px;
  }

  &.image-layout-2,
  &.image-layout-3,
  &.image-layout-4 {
    bottom: 70px;
  }

  & img.portrait {
    // max-width: 60%;
    max-height: 440px;
    width: auto;
  }

  &.image-layout-2 {
    & img.portrait {
      max-height: 360px;
      width: auto;
    }
  }
`;

export const SingleArtist = ({
  data,
  artist,
  index,
  activeArtist,
  setActiveArtist,
  isGridVisible,
}) => {
  return (
    <>
      <Helmet>
        <link
          rel="preload"
          as="image"
          href={artist.node.data.thumbnail.fluid.srcWebp}
          imagesrcset={artist.node.data.thumbnail.fluid.srcSetWebp}
        />
      </Helmet>
      <Artist
        key={`single_artist_${index}_${artist.node.id}`}
        opacity={isGridVisible ? 0 : 1}
        onMouseEnter={() => setActiveArtist(index)}
        onMouseLeave={() => setActiveArtist(null)}
        color={
          activeArtist === null
            ? `#000`
            : activeArtist === index
            ? `#000`
            : `#CCCCCC`
        }
      >
        <Link
          to={artist.node.url}
          state={{
            layout: artist.node.data.name.text[0],
          }}
        >
          <span className="start-letter">
            {artist.node.data.name.text.charAt(0)}
          </span>
          <span className="rest-of-name">
            {artist.node.data.name.text.substring(1)}
          </span>
        </Link>
      </Artist>

      <ActiveArtistImageContainer
        className={`image-layout-${activeArtist}`}
        opacity={activeArtist === index ? 1 : 0}
        visibility={activeArtist === index ? `visible` : `hidden`}
      >
        <img
          className={ImageOrientation(artist.node.data.thumbnail)}
          srcSet={artist.node.data.thumbnail.fluid.srcSetWebp}
          src={artist.node.data.thumbnail.fluid.srcWebp}
          alt={artist.node.data.thumbnail.alt}
          loading={`lazy`}
        />
      </ActiveArtistImageContainer>
    </>
  );
};
